export const REVIEW_FORM_TITLE = 'Thank you for using Cloud Present!';

export const REVIEW_FORM_DESCRIPTION =
  'Please let us know we did and how we can improve.';

export const FORM_RATING_LABEL = 'How did we do?';

export const FORM_MESSAGE_LABEL = 'How can we improve?';

export const FORM_MESSAGE_PLACEHOLDER = 'Let us know what you think';

export const FORM_SUBMIT_BUTTON_TEXT = 'Submit';
