import { ReactNode, useEffect, useRef } from 'react';

import cs from 'classnames';

import styles from './ParticipantGrid.module.scss';

import { GRID_LAYOUT } from '../../config/grid';
import { AspectRatio, PARTICIPANT_PADDING } from '../../constants/grid';

export interface ParticipantGridProps {
  isScreenSharing: boolean;
  numberOfParticipants: number;
  participantVideos?: ReactNode[];
  screenVideo?: ReactNode;
}

export const ParticipantGrid = ({
  isScreenSharing,
  numberOfParticipants,
  participantVideos = [],
  screenVideo
}: ParticipantGridProps) => {
  const gridRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener('resize', () =>
      calculateSize(numberOfParticipants, isScreenSharing)
    );

    return () => {
      window.removeEventListener('resize', () =>
        calculateSize(numberOfParticipants, isScreenSharing)
      );
    };
  }, [numberOfParticipants, isScreenSharing]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      calculateSize(numberOfParticipants, isScreenSharing);
    });

    if (gridRef.current) {
      resizeObserver.observe(gridRef.current);
    }

    return () => {
      if (gridRef.current) {
        resizeObserver.unobserve(gridRef.current);
      }
    };
  }, [gridRef, numberOfParticipants, isScreenSharing]);

  const calculateSize = (nParticipants: number, screenSharing: boolean) => {
    if (gridRef.current) {
      const grid = gridRef.current;
      const gridChildren = grid.children;
      const gridChildrenArray = Array.from(gridChildren);
      const layout =
        GRID_LAYOUT[nParticipants][screenSharing ? 'screen' : 'noScreen'];

      gridChildrenArray.forEach((child, idx) => {
        const childElement = child as HTMLDivElement;
        const childWrapper = childElement.children[0] as HTMLDivElement;
        const childAspectRatio = layout.aspectRatio[idx] || AspectRatio['16:9'];
        const aspectRatioSplit = childAspectRatio.split(':');

        const aspect =
          parseInt(aspectRatioSplit[0]) / parseInt(aspectRatioSplit[1]);
        const ratio = childElement.clientWidth / childElement.clientHeight;

        if (aspect > ratio) {
          const paddedWidth = childElement.clientWidth - PARTICIPANT_PADDING;
          childWrapper.style.width = `${paddedWidth}px`;
          childWrapper.style.height = `${paddedWidth / aspect}px`;
        } else {
          const paddedHeight = childElement.clientHeight - PARTICIPANT_PADDING;
          childWrapper.style.height = `${paddedHeight}px`;
          childWrapper.style.width = `${paddedHeight * aspect}px`;
        }
      });
    }
  };

  const getGridTemplateAreas = (numParticipants: number, screen: boolean) => {
    const screenIndex = screen ? 'screen' : 'noScreen';

    return GRID_LAYOUT[numParticipants][screenIndex].layout ?? `"a"`;
  };

  const gridTemplateAreas = getGridTemplateAreas(
    numberOfParticipants,
    isScreenSharing
  );

  const participantsClass = cs({
    [styles.participants]: true,
    [styles.split]: isScreenSharing
  });

  return (
    <div className={styles.container}>
      <div
        ref={gridRef}
        className={participantsClass}
        style={
          {
            gridTemplateAreas: gridTemplateAreas
          } as React.CSSProperties
        }
      >
        {participantVideos.map((participant, index) => (
          <div
            key={index}
            className={styles.participant}
            style={{
              gridArea: String.fromCharCode(97 + index)
            }} // 'a' is 97 in ASCII
          >
            <div className={styles.participantWrapper}>{participant}</div>
          </div>
        ))}
      </div>
      {isScreenSharing && (
        <div className={styles.screenShare}>{screenVideo}</div>
      )}
    </div>
  );
};
