import styles from './HelpModal.module.scss';

import packageJson from '../../../../package.json';
import { ButtonState } from '../../../constants/buttons';
import { NODE_ENVS } from '../../../constants/env';
import { Accordion } from '../../Accordion/Accordion';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { Modal } from '../../Modal/Modal';

const isProd = import.meta.env.VITE_NODE_ENV === NODE_ENVS.PRODUCTION;
const suffix = !isProd ? '-dev' : '';

const HELP_QUESTIONS = [
  {
    title: 'Can I redo my recordings?',
    content:
      'Yes, stop your current recording. Once it has finished uploading, you can delete the current take and start a new one.'
  },
  {
    title: "I can't see myself?",
    content:
      'Make sure you have a camera connected and have given Cloud Present access to your camera. You can usually see the permissions in the address bar.'
  },
  {
    title: "I can't add any assets?",
    content:
      'If you have shared your screen, you will be required to upload a presentation file. This ensures that we can make your presentation as high-quality as possible. You can upload any file format you want. There is a limit of 10 files, each with a size limit of 1GB.'
  }
];

export interface HelpModalProps {
  onCloseClicked?: () => void;
  open: boolean;
}

export const HelpModal = ({ open, onCloseClicked }: HelpModalProps) => (
  <Modal
    open={open}
    title="Help"
    modalId="help-modal-id"
    onCancel={onCloseClicked}
    rightButtons={
      <PrimaryButton
        text="Close"
        state={ButtonState.Enabled}
        onClick={onCloseClicked}
      />
    }
  >
    <section className={styles.container}>
      <Accordion id="help-accordion" items={HELP_QUESTIONS} />
      <section>
        If your issue is still not resolved, feel free to email us at{' '}
        <b>hello@cloudpresent.co</b>
      </section>
      <section>
        Platform Version: {packageJson.version}
        {suffix}
      </section>
    </section>
  </Modal>
);
