import { useEffect, useRef, useState } from 'react';

import cs from 'classnames';

import styles from './LocalParticipantDisplay.module.scss';

import { HAND_RAISED } from '../../config/participant';
import { LottieAnimationType } from '../../constants/LottieAnimations';
import { hmsActions } from '../../hms';
import useGetAudioStream from '../../hooks/useGetAudioStream';
import { useMediaRecorderContext } from '../../providers/MediaRecorderProvider';
import { AudioLevel } from '../AudioLevelIndicator/AudioLevelIndicator';
import { Avatar } from '../Avatar/Avatar';
import { LottieAnimation } from '../LottieAnimation/LottieAnimation';

export interface LocalParticipantDisplayProps {
  audioLevel: number;
  audioOn: boolean;
  audioTrack: string | undefined;
  isGroup: boolean;
  isGuest: boolean;
  isHandRaised: boolean;
  isHost: boolean;
  isRecording: boolean;
  isSpeaking: boolean;
  networkQuality: number;
  participantName: string;
  selectedAudioInput?: string;
  selectedVideoInput?: string;
  videoOn: boolean;
  videoTrack: string | undefined;
}

export const LocalParticipantDisplay = ({
  participantName,
  audioOn,
  videoOn,
  audioLevel,
  isHost,
  isGuest,
  isHandRaised,
  isSpeaking,
  isRecording,
  isGroup,
  videoTrack,
  audioTrack,
  selectedAudioInput,
  selectedVideoInput
}: LocalParticipantDisplayProps) => {
  const { createCameraMediaRecorder, stopCameraMediaRecorder } =
    useMediaRecorderContext();
  const { audioStream } = useGetAudioStream(audioTrack);

  const [videoAvailable, setVideoAvailable] = useState(false);

  const selectedVideoRef = useRef<string | undefined>(undefined);
  const selectedAudioRef = useRef<string | undefined>(undefined);
  const localVideoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    return () => {
      stopCameraMediaRecorder();
    };
  }, []);

  useEffect(() => {
    if (videoOn && videoTrack && localVideoRef.current) {
      hmsActions.attachVideo(videoTrack, localVideoRef.current);
    } else if (!videoOn && videoTrack && localVideoRef.current) {
      hmsActions.detachVideo(videoTrack, localVideoRef.current);
      setVideoAvailable(false);
    }
  }, [videoTrack, videoOn]);

  useEffect(() => {
    const handleLoadedMetadata = () => {
      setVideoAvailable(!isGuest);
    };

    const videoElement = localVideoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener(
          'loadedmetadata',
          handleLoadedMetadata
        );
      }
    };
  }, [localVideoRef, isGuest]);

  useEffect(() => {
    if (!isGuest && localVideoRef.current && videoAvailable && audioStream) {
      if (
        selectedVideoInput !== selectedVideoRef.current ||
        selectedAudioInput !== selectedAudioRef.current
      ) {
        stopCameraMediaRecorder();
      }

      const videoElement = localVideoRef.current;
      const videoStream: MediaStream = (videoElement as any).captureStream();

      const stream = new MediaStream([
        ...videoStream.getVideoTracks(),
        ...(audioStream?.getAudioTracks() || [])
      ]);

      createCameraMediaRecorder(new MediaRecorder(stream));

      selectedVideoRef.current = selectedVideoInput;
      selectedAudioRef.current = selectedAudioInput;
    }
  }, [videoAvailable, audioStream, selectedVideoInput, selectedAudioInput]);

  const innerContainerClass = cs({
    [styles.innerContainer]: true,
    [styles.speakingBorder]: isSpeaking,
    [styles.loading]: isSpeaking
  });

  const role = isGroup ? (isHost ? ' (Host)' : isGuest ? ' (Guest)' : '') : '';
  const name = `${participantName}${role}`;

  return (
    <section className={styles.container}>
      <div className={innerContainerClass}>
        <video
          ref={localVideoRef}
          className={styles.video}
          autoPlay
          playsInline
          muted={true}
        />
        {!videoOn && (
          <div className={styles.videoDisabled}>
            <Avatar name={participantName} />
          </div>
        )}
      </div>

      {!audioOn && <div className={styles.muted}>Muted</div>}

      <div className={styles.participantName} title={name}>
        {name}
      </div>

      <div className={styles.participantAudio}>
        <AudioLevel audioOn={audioOn} audioLevel={audioLevel} />
      </div>

      {isHandRaised && <div className={styles.handRaised}>{HAND_RAISED}</div>}

      {isRecording && !isGuest && (
        <div className={styles.recording}>
          <LottieAnimation
            animation={LottieAnimationType.RecordingDot}
            loop={true}
          />
        </div>
      )}
    </section>
  );
};
