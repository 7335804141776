import JSZip from 'jszip';

import { ZIPPING_TIME } from '../constants/zip';
import { logger } from './logger';

export const createZip = async (
  files: File[],
  maxTime = ZIPPING_TIME
): Promise<Blob> => {
  const zipping: Promise<Blob> = new Promise((resolve) => {
    const zip = new JSZip();
    for (const blob of files) {
      zip.file(blob.name, blob);
    }
    resolve(zip.generateAsync({ type: 'blob' }));
  });
  const zipTimeout: Promise<Blob> = new Promise((_, reject) =>
    setTimeout(() => {
      logger.error('Error zipping assets', new Error('Failed to zip files'));
      reject(new Error('zipping-timeout'));
    }, maxTime)
  );

  return Promise.race([zipping, zipTimeout]);
};
