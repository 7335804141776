import { useCallback, useRef, useState } from 'react';

import { selectLocalPeer } from '@100mslive/react-sdk';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Upload } from '@aws-sdk/lib-storage';
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler';
import md5 from 'md5';

import { awsConfig } from '../config/aws';
import { METADATA_FILE_ID } from '../constants/metadata';
import { hmsStore } from '../hms';
import { useAppState } from '../providers/AppState';
import { formatBytes, zeroPadding } from '../utils/format';
import { logger } from '../utils/logger';

export interface Chunk {
  blob: Blob;
  filename: string;
  index: number;
}

interface S3AssetUploaderHook {
  isComplete: boolean;
  isUploading: boolean;
  progress: number;
  progressText: string;
  uploadAssets: (blob: Blob) => Promise<void>;
}

interface S3AssetUploaderProps {
  session: number;
  take: number;
}

const useS3AssetUploader = ({
  take
}: S3AssetUploaderProps): S3AssetUploaderHook => {
  const { eventId, sessionData } = useAppState();
  const localPeer = hmsStore.getState(selectLocalPeer);

  const [isUploading, setIsUploading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState('0 B/0 B');

  const s3 = useRef(
    new S3Client({
      region: awsConfig.region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: awsConfig.region }),
        identityPoolId: awsConfig.identityPoolId
      }),
      maxAttempts: awsConfig.maxAttempts,
      requestHandler: new XhrHttpHandler({})
    })
  );

  const retryUpload = async (uploadTask: Upload, retries = 3) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        await uploadTask.done();
        return;
      } catch (error) {
        logger.warn(`Upload attempt ${attempt} failed:`, error);
        if (attempt === retries) throw error;
      }
    }
  };

  const uploadAssets = useCallback(
    async (blob: Blob) => {
      if (!sessionData || !localPeer) {
        logger.error('Missing sessionData or localPeer.');
        return;
      }

      setIsUploading(true);
      setIsComplete(false);

      const timestamp = Math.floor(new Date().getTime() / 1000);
      const filename = `${zeroPadding(take)}_${timestamp}_${sessionData.uploadId}_assets`;
      const metadata: Record<string, string> = {
        [METADATA_FILE_ID]: md5(filename)
      };

      const uploadTask = new Upload({
        client: s3.current,
        queueSize: 1,
        leavePartsOnError: false,
        params: {
          Bucket: awsConfig.bucket,
          Key: `assets/originals/${eventId}/${sessionData.uploadId}/${localPeer.id}/${filename}.zip`,
          Body: blob,
          ContentType: 'application/zip',
          Metadata: metadata
        }
      });

      uploadTask.on('httpUploadProgress', (progress) => {
        const totalCompleted = progress.loaded || 0;
        setProgress((totalCompleted / blob.size) * 100);
        setProgressText(
          `${formatBytes(totalCompleted)}/${formatBytes(blob.size)}`
        );
      });

      try {
        await retryUpload(uploadTask, 3);
        setIsComplete(true);
      } catch (error) {
        logger.error('Upload failed after retries:', error);
        await uploadTask.abort();
      } finally {
        setIsUploading(false);
      }
    },
    [take, sessionData, localPeer]
  );

  return {
    isUploading,
    isComplete,
    progress,
    progressText,
    uploadAssets
  };
};

export default useS3AssetUploader;
