import styles from './UploadingModal.module.scss';

import { LottieAnimationType } from '../../../constants/LottieAnimations';
import { ButtonState } from '../../../constants/buttons';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { LottieAnimation } from '../../LottieAnimation/LottieAnimation';
import { Modal } from '../../Modal/Modal';

export interface UploadingModalProps {
  complete?: boolean;
  isHost?: boolean;
  onNextClicked: () => void;
  open: boolean;
}

export const UploadingModal = ({
  open,
  isHost = false,
  complete = false,
  onNextClicked
}: UploadingModalProps) => (
  <Modal
    open={open}
    title={complete ? 'Upload complete' : 'Uploading'}
    modalId="help-modal-id"
    disableClose={true}
    rightButtons={
      <>
        {complete && (
          <PrimaryButton
            text={isHost ? 'Next' : 'Close'}
            state={ButtonState.Enabled}
            onClick={onNextClicked}
          />
        )}
      </>
    }
  >
    <section className={styles.container}>
      {complete ? (
        <div className={styles.complete}>
          <LottieAnimation
            animation={LottieAnimationType.Completed}
            loop={false}
          />
        </div>
      ) : (
        <div className={styles.uploadingContainer}>
          <div className={styles.uploading}>
            <LottieAnimation
              animation={LottieAnimationType.Uploading}
              loop={true}
            />
          </div>
          <p>Uploading...</p>
        </div>
      )}
      {isHost ? (
        <p>
          Once we have finished uploading your video files, you will be able to
          select assets and add notes or skip if you have nothing to add. You
          will be able to delete the take in the next step.{' '}
          <b>
            (Important: Please do not close the tab until the upload is
            complete)
          </b>
        </p>
      ) : (
        <p>
          Once we have finished uploading your video files you can close this
          dialog and continue to chat to your fellow participants.{' '}
          <b>
            (Important: Please do not close the tab until the upload is
            complete)
          </b>
        </p>
      )}
    </section>
  </Modal>
);
