import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DeviceType, HMSPeer } from '@100mslive/react-sdk';

import styles from './SettingsForm.module.scss';

import Participant from '../../../components/Participant/Participant';
import {
  BACKGROUND_SIZE_ERROR,
  BACKGROUND_SIZE_NOTIFICATION,
  Backgrounds,
  SUPPORT_BACKGROUNDS
} from '../../../config/backgrounds';
import { Size } from '../../../constants/Size';
import { MAX_NAME_LENGTH, MIN_NAME_LENGTH } from '../../../constants/app';
import {
  Background,
  MAX_BACKGROUND_IMAGE_SIZE
} from '../../../constants/background';
import { ButtonState } from '../../../constants/buttons';
import { DEFAULT_NOTIFICATION_TIMEOUT } from '../../../constants/notifications';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import { SecondaryIconButton } from '../../IconButtons/SecondaryIconButton/SecondaryIconButton';
import { Block } from '../../Icons/Block/Block';
import { Blur } from '../../Icons/Blur/Blur';
import { LoadingSpinnerIcon } from '../../Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';
import { Plus } from '../../Icons/Plus/Plus';
import { SlightBlur } from '../../Icons/SlightBlur/SlightBlur';
import { PrimaryImageButton } from '../../ImageButtons/ImageButtons';
import { Select } from '../../Inputs/Select/Select';
import { Option } from '../../Inputs/Select/SelectMenu/SelectOption/SelectOption';
import {
  TEXT_INPUT_AUTOCOMPLETE,
  TextInput
} from '../../Inputs/TextInput/TextInput';
import { Toast, ToastType } from '../../Toast/Toast';
import { Tooltip, TooltipPosition } from '../../Tooltip/Tooltip';

export interface SettingsFormProps {
  audioInputs: Option[];
  audioOutputs: Option[];
  background: Background;
  loadingPeer: boolean;
  onDeviceChanged: (selected: Option, type: DeviceType) => void;
  onNameChanged: (name: string) => void;
  peer?: HMSPeer;
  selectedAudioInput?: Option;
  selectedAudioOutput?: Option;
  selectedVideoInput?: Option;
  setBackground: (selected: Background, url?: string) => void;
  videoInputs: Option[];
}

export const SettingsForm = ({
  selectedAudioInput,
  videoInputs,
  selectedVideoInput,
  audioInputs,
  selectedAudioOutput,
  audioOutputs,
  loadingPeer,
  peer,
  background,
  onDeviceChanged,
  onNameChanged,
  setBackground
}: SettingsFormProps) => {
  const [name, setName] = useState('');

  useEffect(() => {
    if (peer?.name) setName(peer.name);
  }, []);

  const handleNameChanged = (name: string) => setName(name);

  const handleSaveName = () => onNameChanged(name);

  const handleFileSelect = (file: File) => {
    if (!file) return;
    if (file.size > MAX_BACKGROUND_IMAGE_SIZE) {
      toast(
        <Toast
          title={BACKGROUND_SIZE_ERROR.title}
          subtitle={BACKGROUND_SIZE_ERROR.message}
          type={ToastType.Error}
        />,
        {
          autoClose: DEFAULT_NOTIFICATION_TIMEOUT
        }
      );

      return;
    }

    const url = URL.createObjectURL(file);
    setBackground(Background.Add, url);

    toast(
      <Toast
        title={BACKGROUND_SIZE_NOTIFICATION.title}
        subtitle={BACKGROUND_SIZE_NOTIFICATION.message}
        type={ToastType.Info}
      />,
      {
        autoClose: DEFAULT_NOTIFICATION_TIMEOUT
      }
    );
  };

  return (
    <section className={styles.wrapper}>
      {loadingPeer || !peer ? (
        <div className={styles.display}>
          <div className={styles.inner}>
            <LoadingSpinnerIcon color="white" />
          </div>
        </div>
      ) : (
        <Participant peer={peer} isPreview={true} />
      )}

      <div className={styles.container}>
        <section className={styles.name}>
          <TextInput
            id="local-peer-name"
            label="Your name"
            autocomplete={TEXT_INPUT_AUTOCOMPLETE.FIRST_NAME}
            value={name}
            type="text"
            minLength={MIN_NAME_LENGTH}
            maxLength={MAX_NAME_LENGTH}
            onChange={handleNameChanged}
          />
          <PrimaryButton
            text="Save"
            size={Size.Small}
            state={
              name.length > MIN_NAME_LENGTH
                ? ButtonState.Active
                : ButtonState.Disabled
            }
            onClick={handleSaveName}
          />
        </section>

        <section className={styles.actions}>
          <Tooltip
            text="Remove effects"
            position={TooltipPosition.NORTH}
            disabled={false}
          >
            <SecondaryIconButton
              icon={<Block />}
              state={
                background === Background.None
                  ? ButtonState.Disabled
                  : ButtonState.Enabled
              }
              onClick={() => setBackground(Background.None)}
            />
          </Tooltip>

          <Tooltip
            text="Light Blur"
            position={TooltipPosition.NORTH}
            disabled={false}
          >
            <SecondaryIconButton
              icon={<SlightBlur />}
              state={
                background === Background.LightBlur
                  ? ButtonState.Active
                  : ButtonState.Enabled
              }
              onClick={() => setBackground(Background.LightBlur)}
            />
          </Tooltip>

          <Tooltip
            text="Blur"
            position={TooltipPosition.NORTH}
            disabled={false}
          >
            <SecondaryIconButton
              icon={<Blur />}
              state={
                background === Background.NormalBlur
                  ? ButtonState.Active
                  : ButtonState.Enabled
              }
              onClick={() => setBackground(Background.NormalBlur)}
            />
          </Tooltip>

          <PrimaryImageButton
            image={Backgrounds.Image1.thumb}
            state={
              background === Background.Image1
                ? ButtonState.Active
                : ButtonState.Enabled
            }
            onClick={() => setBackground(Background.Image1)}
          />

          <PrimaryImageButton
            image={Backgrounds.Image2.thumb}
            state={
              background === Background.Image2
                ? ButtonState.Active
                : ButtonState.Enabled
            }
            onClick={() => setBackground(Background.Image2)}
          />
        </section>

        <section className={styles.actions}>
          <PrimaryImageButton
            image={Backgrounds.Image3.thumb}
            state={
              background === Background.Image3
                ? ButtonState.Active
                : ButtonState.Enabled
            }
            onClick={() => setBackground(Background.Image3)}
          />

          <PrimaryImageButton
            image={Backgrounds.Image6.thumb}
            state={
              background === Background.Image6
                ? ButtonState.Active
                : ButtonState.Enabled
            }
            onClick={() => setBackground(Background.Image6)}
          />

          <PrimaryImageButton
            image={Backgrounds.Image7.thumb}
            state={
              background === Background.Image7
                ? ButtonState.Active
                : ButtonState.Enabled
            }
            onClick={() => setBackground(Background.Image7)}
          />

          <PrimaryImageButton
            image={Backgrounds.Image8.thumb}
            state={
              background === Background.Image8
                ? ButtonState.Active
                : ButtonState.Enabled
            }
            onClick={() => setBackground(Background.Image8)}
          />

          <Tooltip
            text="Add your own background"
            position={TooltipPosition.NORTH}
            disabled={false}
          >
            <SecondaryIconButton
              icon={<Plus />}
              state={
                background === Background.Add
                  ? ButtonState.Active
                  : ButtonState.Enabled
              }
              isUpload={true}
              onFileSelect={handleFileSelect}
              acceptedFiles={SUPPORT_BACKGROUNDS}
            />
          </Tooltip>
        </section>

        <Select
          dark={true}
          label="Select camera"
          onChange={(value) => onDeviceChanged(value, DeviceType.videoInput)}
          options={videoInputs}
          selected={selectedVideoInput}
          placeholder="Select a camera"
        />
        <Select
          dark={true}
          label="Select microphone"
          onChange={(value) => onDeviceChanged(value, DeviceType.audioInput)}
          options={audioInputs}
          selected={selectedAudioInput}
          placeholder="Select a microphone"
        />
        <Select
          dark={true}
          label="Select speaker"
          onChange={(value) => onDeviceChanged(value, DeviceType.audioOutput)}
          options={audioOutputs}
          selected={selectedAudioOutput}
          placeholder="Select a speaker"
        />
      </div>
    </section>
  );
};
