import { ChangeEvent, ReactNode, useRef } from 'react';

import cs from 'classnames';

import styles from './SecondaryIconButton.module.scss';

import { ButtonState, ButtonType } from '../../../constants/buttons';
import { LoadingSpinnerIcon } from '../../Icons/LoadingSpinnerIcon/LoadingSpinnerIcon';

export interface SecondaryIconButtonProps {
  acceptedFiles?: string;
  icon: ReactNode;
  /**
   * Set to true if this button should behave as an "upload" button.
   * If true, clicking the button opens a hidden <input type="file" />.
   */
  isUpload?: boolean;
  notification?: boolean;
  onClick?: () => void | Promise<void>;

  /**
   * A callback invoked when the user selects a file. Only used if isUpload = true.
   */
  onFileSelect?: (file: File) => void;

  state?: ButtonState;
  type?: ButtonType;
}

export const SecondaryIconButton = ({
  icon,
  notification = false,
  onClick,
  state = ButtonState.Enabled,
  type = ButtonType.Button,
  isUpload = false,
  acceptedFiles,
  onFileSelect
}: SecondaryIconButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (isUpload) {
      // If this is an upload button, trigger the hidden <input>.
      fileInputRef.current?.click();
    } else {
      // Otherwise, just run the normal onClick handler.
      onClick?.();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    // If you only want single-file uploads, just pass e.target.files[0].
    // If you want multiple, you could pass the entire FileList.
    onFileSelect?.(e.target.files[0]);
  };

  return (
    <>
      <button
        type={type}
        className={cs({
          [styles.button]: true,
          [styles[state]]: true
        })}
        onClick={handleButtonClick}
      >
        {state === ButtonState.Loading ? (
          <LoadingSpinnerIcon color="white" />
        ) : (
          icon
        )}
        {notification && <span className={styles.notification} />}
      </button>

      {/* 
        Hidden file input. Only rendered if this button is meant for upload.
        The input is hidden via style, but will be clicked programmatically
        if the user clicks the main button (when isUpload = true).
      */}
      {isUpload && (
        <input
          ref={fileInputRef}
          type="file"
          accept={acceptedFiles}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      )}
    </>
  );
};
