import { useEffect, useRef } from 'react';

import { selectIsLocalScreenShared, useHMSStore } from '@100mslive/react-sdk';

import { useRecordingContext } from '../providers/RecordingProvider';
import { logger } from '../utils/logger';

export default function useScreenMediaRecorder() {
  const isScreenShared = useHMSStore(selectIsLocalScreenShared);
  const { isRecording, uploadScreen, storeScreenChunk } = useRecordingContext();
  const screenMediaRecorderRef = useRef<MediaRecorder | null>(null);

  const createScreenMediaRecorder = (ref: MediaRecorder | null) => {
    if (!ref && screenMediaRecorderRef.current) {
      screenMediaRecorderRef.current.stop();
    }

    screenMediaRecorderRef.current = ref;
  };

  const stopScreenMediaRecorder = () => {
    if (screenMediaRecorderRef.current?.state === 'recording') {
      screenMediaRecorderRef.current.stop();
    } else {
      screenMediaRecorderRef.current = null;
    }
  };

  useEffect(() => {
    if (!screenMediaRecorderRef.current) return;

    const handleDataAvailable = (event: BlobEvent) => {
      uploadScreen(event.data);
      storeScreenChunk(event.data);
    };

    const handleStop = () => {
      if (!isScreenShared) {
        screenMediaRecorderRef.current = null;
      }
    };

    screenMediaRecorderRef.current.ondataavailable = handleDataAvailable;
    screenMediaRecorderRef.current.onstop = handleStop;

    screenMediaRecorderRef.current.onstart = () => {
      logger.info('Screen recording started');
    };

    screenMediaRecorderRef.current.onerror = (event) => {
      logger.error('Screen MediaRecorder error:', event);
    };

    return () => {
      if (screenMediaRecorderRef.current) {
        screenMediaRecorderRef.current.ondataavailable = null;
        screenMediaRecorderRef.current.onstop = null;
        screenMediaRecorderRef.current.onerror = null;
        screenMediaRecorderRef.current.onstart = null;
      }
    };
  }, [
    screenMediaRecorderRef.current,
    isScreenShared,
    uploadScreen,
    storeScreenChunk
  ]);

  useEffect(() => {
    if (!screenMediaRecorderRef.current) return;

    if (
      isScreenShared &&
      isRecording &&
      screenMediaRecorderRef.current.state === 'inactive'
    ) {
      screenMediaRecorderRef.current.start();
    } else if (
      !isRecording &&
      screenMediaRecorderRef.current.state === 'recording'
    ) {
      screenMediaRecorderRef.current.stop();
    }
  }, [isRecording, screenMediaRecorderRef.current, isScreenShared]);

  return { createScreenMediaRecorder, stopScreenMediaRecorder };
}
