import { useEffect, useState } from 'react';

import {
  selectLocalPeer,
  selectPeers,
  useHMSStore
} from '@100mslive/react-sdk';
import { getDatabase, off, onValue, ref } from 'firebase/database';

import { Roles } from '../../constants/roles';
import { hmsStore } from '../../hms';
import { useAppState } from '../../providers/AppState';
import { ParticipantList } from '../../stories/ParticipantList/ParticipantList';
import { ParticipantDetails, UploadState } from '../../types/participant.type';

export interface ParticipantListWrapperProps {
  close: () => void;
  open: boolean;
}

type ParticipantDetailsMap = {
  [id: string]: {
    uploading: {
      progress: string;
      state: UploadState;
    };
  };
};

export const ParticipantListWrapper = ({
  open,
  close
}: ParticipantListWrapperProps) => {
  const { sessionData } = useAppState();

  const [participantDetails, setParticipantDetails] = useState<
    Record<string, ParticipantDetails>
  >({});

  const peers = useHMSStore(selectPeers);
  const localPeer = hmsStore.getState(selectLocalPeer);

  const sessionDetailsRef = ref(
    getDatabase(),
    `sessions/${sessionData?.uploadId}/participants`
  );

  useEffect(() => {
    if (open && sessionData?.uploadId) {
      onValue(sessionDetailsRef, (snapshot) => {
        const data: ParticipantDetailsMap =
          snapshot.val() as ParticipantDetailsMap;
        setParticipantDetails(
          peers.reduce(
            (acc, it) => {
              acc[it.id] = {
                id: it.id,
                state: data[it.id].uploading.state || UploadState.UNKNOWN,
                progress: data[it.id].uploading.progress || '0%'
              };

              return acc;
            },
            {} as Record<string, ParticipantDetails>
          )
        );
      });
    }

    return () => off(sessionDetailsRef);
  }, [open]);

  return (
    <ParticipantList
      isHost={localPeer?.roleName === Roles.Host}
      participants={peers}
      participantDetails={participantDetails}
      close={close}
    />
  );
};
