import styles from './SessionControls.module.scss';

import { ButtonState } from '../../constants/buttons';
import { RecordButton } from '../Buttons/RecordButton/RecordingButton';
import { PrimaryIconButton } from '../IconButtons/PrimaryIconButton/PrimaryIconButton';
import { MicOff } from '../Icons/MicOff/MicOff';
import { MicOn } from '../Icons/MicOn/MicOn';
import { RaisedHandOff } from '../Icons/RaisedHandOff/RaisedHandOff';
import { RaisedHandOn } from '../Icons/RaisedHandOn/RaisedHandOn';
import { ScreenShareOff } from '../Icons/ScreenShareOff/ScreenShareOff';
import { ScreenShareOn } from '../Icons/ScreenShareOn/ScreenShareOn';
import { Settings } from '../Icons/Settings/Settings';
import { VideoOff } from '../Icons/VideoOff/VideoOff';
import { VideoOn } from '../Icons/VideoOn/VideoOn';
import { Tooltip, TooltipPosition } from '../Tooltip/Tooltip';

export interface SessionControls {
  audioEnabled: boolean;
  isRecording: boolean;
  screenShareEnabled: boolean;
  videoEnabled: boolean;
}

export interface SessionControlsProps {
  allParticipantsVideoEnabled: boolean;
  audioEnabled: boolean;
  isGroup: boolean;
  isGuest: boolean;
  isHandRaised: boolean;
  isHost: boolean;
  isLocalSharingScreen: boolean;
  isRecording: boolean;
  onAudioClicked: () => void | Promise<void>;
  onRaiseHandClicked: () => void | Promise<void>;
  onRecordingClicked: () => void | Promise<void>;
  onScreenShareClicked: () => void | Promise<void>;
  onSettingsClicked: () => void;
  onVideoClicked: () => void | Promise<void>;
  someoneScreenSharing: boolean;
  videoEnabled: boolean;
}

export const SessionControls = ({
  isGroup,
  isHost,
  isGuest,
  videoEnabled,
  allParticipantsVideoEnabled,
  audioEnabled,
  isLocalSharingScreen,
  someoneScreenSharing,
  isRecording,
  isHandRaised,
  onVideoClicked,
  onAudioClicked,
  onScreenShareClicked,
  onRecordingClicked,
  onRaiseHandClicked,
  onSettingsClicked
}: SessionControlsProps) => (
  <hgroup className={styles.container}>
    {isGroup && (
      <Tooltip
        text={isHandRaised ? 'Lower hand' : 'Raise hand'}
        position={TooltipPosition.NORTH}
        disabled={false}
      >
        <PrimaryIconButton
          icon={isHandRaised ? <RaisedHandOff /> : <RaisedHandOn />}
          state={isHandRaised ? ButtonState.Active : ButtonState.Enabled}
          onClick={onRaiseHandClicked}
        />
      </Tooltip>
    )}
    {isHost && (
      <Tooltip
        text={'Stop recording'}
        position={TooltipPosition.NORTH}
        disabled={!isRecording}
      >
        <RecordButton
          recording={isRecording}
          state={
            allParticipantsVideoEnabled
              ? ButtonState.Enabled
              : ButtonState.Disabled
          }
          onClick={onRecordingClicked}
        />
      </Tooltip>
    )}
    <Tooltip
      text={
        isRecording
          ? "You can't switch video off while recording"
          : videoEnabled
            ? 'Turn off video'
            : 'Turn on video'
      }
      position={TooltipPosition.NORTH}
      disabled={false}
    >
      <PrimaryIconButton
        icon={videoEnabled ? <VideoOn /> : <VideoOff />}
        state={
          isRecording
            ? ButtonState.Disabled
            : videoEnabled
              ? ButtonState.Enabled
              : ButtonState.Active
        }
        onClick={onVideoClicked}
      />
    </Tooltip>
    <Tooltip
      text={
        isRecording && !isGroup
          ? "You can't switch audio off while recording"
          : audioEnabled
            ? 'Turn off audio'
            : 'Turn on audio'
      }
      position={TooltipPosition.NORTH}
      disabled={false}
    >
      <PrimaryIconButton
        icon={audioEnabled ? <MicOn /> : <MicOff />}
        state={
          isRecording && !isGroup
            ? ButtonState.Disabled
            : audioEnabled
              ? ButtonState.Enabled
              : ButtonState.Active
        }
        onClick={onAudioClicked}
      />
    </Tooltip>
    <Tooltip
      text={
        isGuest
          ? 'Guests cannot share screen'
          : someoneScreenSharing && !isLocalSharingScreen
            ? 'Only one screen share allowed'
            : isLocalSharingScreen
              ? 'Stop screen share'
              : 'Start screen share'
      }
      position={TooltipPosition.NORTH}
      disabled={false}
    >
      <PrimaryIconButton
        icon={
          isLocalSharingScreen || someoneScreenSharing ? (
            <ScreenShareOff />
          ) : (
            <ScreenShareOn />
          )
        }
        state={
          (someoneScreenSharing && !isLocalSharingScreen) || isGuest
            ? ButtonState.Disabled
            : isLocalSharingScreen
              ? ButtonState.Active
              : ButtonState.Enabled
        }
        onClick={onScreenShareClicked}
      />
    </Tooltip>
    <Tooltip
      text={
        isRecording ? "You can't change settings while recording" : 'Settings'
      }
      position={TooltipPosition.NORTH}
      disabled={false}
    >
      <PrimaryIconButton
        icon={<Settings />}
        state={isRecording ? ButtonState.Disabled : ButtonState.Enabled}
        onClick={onSettingsClicked}
      />
    </Tooltip>
  </hgroup>
);
