import { useCallback, useEffect, useMemo, useState } from 'react';

import { getDatabase, off, onValue, ref, set } from 'firebase/database';

import { JoinStep } from '../pages/Join/Join';

export default function useHostPresent(
  step: JoinStep,
  eventId?: string,
  groupId?: string
) {
  const [hostPresent, setHostPresent] = useState(false);
  const db = getDatabase();

  // Memoize the Firebase reference
  const hostPresentGroupRef = useMemo(() => {
    return eventId && groupId
      ? ref(db, `groupRecordings/${eventId}/${groupId}/hostPresent`)
      : null;
  }, [db, eventId, groupId]);

  useEffect(() => {
    if (!hostPresentGroupRef || step !== JoinStep.DeviceSelection) return;

    const unsubscribe = onValue(hostPresentGroupRef, (snapshot) => {
      setHostPresent(snapshot.val() as boolean);
    });

    return () => off(hostPresentGroupRef, 'value', unsubscribe);
  }, [hostPresentGroupRef, step]);

  const updateHostPresent = useCallback(async () => {
    if (hostPresentGroupRef) await set(hostPresentGroupRef, true);
  }, [hostPresentGroupRef]);

  return { hostPresent, updateHostPresent };
}
