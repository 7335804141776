import { useEffect, useRef, useState } from 'react';

import { selectLocalPeer } from '@100mslive/react-sdk';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Upload } from '@aws-sdk/lib-storage';
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler';
import md5 from 'md5';
import PQueue from 'p-queue';

import { awsConfig } from '../config/aws';
import { METADATA_FILE_ID } from '../constants/metadata';
import { hmsStore } from '../hms';
import { useAppState } from '../providers/AppState';
import { formatBytes, zeroPadding } from '../utils/format';
import { logger } from '../utils/logger';
import useUploadProgress from './useUploadProgress';

const UPLOAD_RETRIES = 3;
const UPLOAD_RETRY_BACKOFF = 10;

const enum ChunkType {
  Camera = 'camera',
  Screen = 'screen'
}

const ChunkPaths = {
  [ChunkType.Camera]: 'recording-chunks',
  [ChunkType.Screen]: 'screen-recordings/originals'
};

export interface Chunk {
  blob: Blob;
  filename: string;
  index: number;
  type: ChunkType;
}

interface S3ChunkUploaderHook {
  isComplete: boolean;
  isUploading: boolean;
  progress: number;
  progressText: string;
  uploadChunk: (blob: Blob) => void;
  uploadScreen: (blob: Blob) => void;
}

interface S3ChunkUploaderProps {
  cameraChunkNumber: number;
  incrementCameraChunkNumber: () => void;
  incrementScreenChunkNumber: () => void;
  isRecording: boolean;
  resetChunkNumber: () => void;
  screenChunkNumber: number;
  session: number;
  take: number;
}

const useS3ChunkUploader = ({
  isRecording,
  session,
  take,
  cameraChunkNumber,
  screenChunkNumber,
  incrementCameraChunkNumber,
  incrementScreenChunkNumber,
  resetChunkNumber
}: S3ChunkUploaderProps): S3ChunkUploaderHook => {
  const { eventId, sessionData } = useAppState();
  const localPeer = hmsStore.getState(selectLocalPeer);
  const { updateUploadProgress, completeUploadProgress } = useUploadProgress();

  const [isUploading, setIsUploading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const cameraFilenameRef = useRef<string | null>(null);
  const screenFilenameRef = useRef<string | null>(null);
  const totalUploadedSizeRef = useRef(0);
  const totalUploadSizeRef = useRef(0);

  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState('0 B/0 B');

  const queue = useRef(new PQueue({ concurrency: 1 }));

  const s3 = useRef(
    new S3Client({
      region: awsConfig.region,
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: awsConfig.region }),
        identityPoolId: awsConfig.identityPoolId
      }),
      maxAttempts: awsConfig.maxAttempts,
      requestHandler: new XhrHttpHandler({}) // https://github.com/aws/aws-sdk-js-v3/tree/main/packages/xhr-http-handler
    })
  );

  useEffect(() => {
    if (isRecording) {
      queue.current.clear();
      totalUploadedSizeRef.current = 0;
      totalUploadSizeRef.current = 0;

      setProgress(0);
      setProgressText('0 B/0 B');
      setIsComplete(false);
      resetChunkNumber();
    }
  }, [isRecording]);

  const uploadChunkToS3 = async ({ filename, blob, type }: Chunk) => {
    if (!localPeer || !sessionData) {
      logger.error('Missing localPeer or sessionData');
      return;
    }

    setIsUploading(true);
    setIsComplete(false);

    const key = `${ChunkPaths[type]}/${eventId}/${sessionData.uploadId}/${localPeer.id}/${filename}.webm`;
    const metadata: Record<string, string> = {};
    if (type === ChunkType.Screen) {
      metadata[METADATA_FILE_ID] = md5(key);
    }

    for (let attempt = 1; attempt <= UPLOAD_RETRIES; attempt++) {
      logger.info(`Attempt ${attempt} to upload chunk: ${key}`);

      const uploadTask = new Upload({
        client: s3.current,
        queueSize: 1,
        partSize: 1024 * 1024 * 5,
        params: {
          Bucket: awsConfig.bucket,
          Key: key,
          Body: blob,
          ContentType: 'video/webm',
          Metadata: metadata
        }
      });

      try {
        await uploadTask.done();

        totalUploadedSizeRef.current += blob.size;

        const percentage =
          (totalUploadedSizeRef.current / totalUploadSizeRef.current) * 100;
        setProgress(percentage);
        setProgressText(
          `${formatBytes(totalUploadedSizeRef.current)}/${formatBytes(totalUploadSizeRef.current)}`
        );

        logger.info(
          `Upload progress: ${percentage.toFixed(1)}% [${progressText}]`
        );
        await updateUploadProgress(
          sessionData.uploadId,
          localPeer.id,
          `${percentage.toFixed(1)}%`
        );

        return;
      } catch (error) {
        logger.error(`Upload attempt ${attempt} failed:`, error);

        if (attempt < UPLOAD_RETRIES) {
          await new Promise((res) =>
            setTimeout(res, UPLOAD_RETRY_BACKOFF * 1000)
          );
        }

        if (attempt === UPLOAD_RETRIES) {
          throw error;
        }
      }
    }
  };

  const addToQueue = (chunk: Chunk) => {
    totalUploadSizeRef.current += chunk.blob.size;
    logger.info('Adding to queue', chunk);

    queue.current
      .add(() => uploadChunkToS3(chunk))
      .then(() => {
        if (!localPeer || !sessionData) {
          logger.error('Missing localPeer or sessionData');
          return;
        }

        if (
          !isRecording &&
          queue.current.size === 0 &&
          queue.current.pending === 0
        ) {
          setIsComplete(true);
          completeUploadProgress(sessionData.uploadId, localPeer.id);
        }
      });
  };

  const uploadChunk = (blob: Blob) => {
    addToQueue({
      index: cameraChunkNumber,
      filename: cameraFilenameRef.current!,
      blob,
      type: ChunkType.Camera
    });
    incrementCameraChunkNumber();
  };

  const uploadScreen = (blob: Blob) => {
    addToQueue({
      index: 0,
      filename: screenFilenameRef.current!,
      blob,
      type: ChunkType.Screen
    });
    incrementScreenChunkNumber();
  };

  useEffect(() => {
    cameraFilenameRef.current = `${zeroPadding(take)}_${zeroPadding(
      cameraChunkNumber
    )}_${zeroPadding(session)}_camera`;
  }, [session, take, cameraChunkNumber]);

  useEffect(() => {
    screenFilenameRef.current = `${zeroPadding(take)}_${zeroPadding(
      screenChunkNumber
    )}_${zeroPadding(session)}_screen`;
  }, [session, take, screenChunkNumber]);

  return {
    isUploading,
    isComplete,
    progress,
    progressText,
    uploadChunk,
    uploadScreen
  };
};

export default useS3ChunkUploader;
