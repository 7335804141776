import { useEffect, useRef } from 'react';

import { selectIsLocalVideoEnabled, useHMSStore } from '@100mslive/react-sdk';

import { CHUNK_LENGTH } from '../config/recording';
import { useRecordingContext } from '../providers/RecordingProvider';
import { logger } from '../utils/logger';

export default function useCameraMediaRecorder() {
  const isCameraEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const { isRecording, uploadChunk, storeCameraChunk } = useRecordingContext();
  const cameraMediaRecorderRef = useRef<MediaRecorder | null>(null);

  const createCameraMediaRecorder = (ref: MediaRecorder | null) => {
    if (!ref && cameraMediaRecorderRef.current?.state === 'recording') {
      cameraMediaRecorderRef.current.stop();
    }

    cameraMediaRecorderRef.current = ref;
  };

  const stopCameraMediaRecorder = () => {
    if (cameraMediaRecorderRef.current) {
      cameraMediaRecorderRef.current.stop();
    }
  };

  useEffect(() => {
    if (!cameraMediaRecorderRef.current) return;

    const handleDataAvailable = (event: BlobEvent) => {
      logger.info('Got chunk', event);

      uploadChunk(event.data);
      storeCameraChunk(event.data);
    };

    const handleStop = () => {
      if (!isCameraEnabled) cameraMediaRecorderRef.current = null;
    };

    cameraMediaRecorderRef.current.ondataavailable = handleDataAvailable;
    cameraMediaRecorderRef.current.onstop = handleStop;

    cameraMediaRecorderRef.current.onstart = () => {
      logger.info('Camera recording started');
    };

    cameraMediaRecorderRef.current.onerror = (event) => {
      logger.error('MediaRecorder error:', event);
    };

    return () => {
      if (cameraMediaRecorderRef.current) {
        cameraMediaRecorderRef.current.ondataavailable = null;
        cameraMediaRecorderRef.current.onstop = null;
        cameraMediaRecorderRef.current.onerror = null;
        cameraMediaRecorderRef.current.onstart = null;
      }
    };
  }, [cameraMediaRecorderRef, uploadChunk, storeCameraChunk]);

  useEffect(() => {
    if (!cameraMediaRecorderRef.current) return;

    if (isRecording && cameraMediaRecorderRef.current.state === 'inactive') {
      cameraMediaRecorderRef.current.start(CHUNK_LENGTH);
    } else if (
      !isRecording &&
      cameraMediaRecorderRef.current.state === 'recording'
    ) {
      cameraMediaRecorderRef.current.stop();
    }
  }, [isRecording, cameraMediaRecorderRef]);

  return {
    createCameraMediaRecorder,
    stopCameraMediaRecorder,
    cameraMediaRecorder: cameraMediaRecorderRef
  };
}
