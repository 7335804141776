import { useCallback, useEffect, useMemo, useState } from 'react';

import { get, getDatabase, off, onValue, ref, set } from 'firebase/database';

export default function useSomeoneScreenSharing(
  eventId?: string,
  groupId?: string
) {
  const [someoneScreenSharing, setSomeoneScreenSharing] = useState(false);
  const db = getDatabase();

  // Memoize the Firebase reference
  const recordingGroupRef = useMemo(() => {
    return eventId && groupId
      ? ref(db, `groupRecordings/${eventId}/${groupId}/screenSharing`)
      : null;
  }, [db, eventId, groupId]);

  useEffect(() => {
    if (!recordingGroupRef) return;

    // Get initial state
    get(recordingGroupRef).then((snapshot) => {
      setSomeoneScreenSharing(snapshot.val() as boolean);
    });

    const unsubscribe = onValue(recordingGroupRef, (snapshot) => {
      setSomeoneScreenSharing(snapshot.val() as boolean);
    });

    return () => off(recordingGroupRef, 'value', unsubscribe);
  }, [recordingGroupRef]);

  useEffect(() => {
    if (recordingGroupRef && someoneScreenSharing !== null) {
      set(recordingGroupRef, someoneScreenSharing);
    }
  }, [recordingGroupRef, someoneScreenSharing]);

  const toggleScreenSharing = useCallback(() => {
    setSomeoneScreenSharing((prev) => {
      const newState = !prev;
      if (recordingGroupRef) {
        set(recordingGroupRef, newState);
      }
      return newState;
    });
  }, [recordingGroupRef]);

  return { someoneScreenSharing, toggleScreenSharing, setSomeoneScreenSharing };
}
