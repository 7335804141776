import { useEffect, useRef, useState } from 'react';

import { logger } from '../utils/logger';

export default function useGetAudioStream(trackId?: string) {
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const audioStreamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    if (!trackId) return;

    async function setup() {
      try {
        // Stop previous stream before setting up a new one
        if (audioStreamRef.current) {
          audioStreamRef.current.getTracks().forEach((track) => track.stop());
        }

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: trackId }
        });

        audioStreamRef.current = stream;
        setAudioStream(stream);
      } catch (error) {
        logger.error('Error accessing audio stream:', error);
      }
    }

    setup();

    return () => {
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach((track) => track.stop());
        audioStreamRef.current = null;
      }
    };
  }, [trackId]);

  return { audioStream };
}
