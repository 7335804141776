import { useCallback } from 'react';

import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp
} from 'firebase/firestore';

import { TIME_CODE } from '../constants/timeCode';
import { logger } from '../utils/logger';

export default function useCreateTimeCode() {
  const firestore = getFirestore();

  const addTimeCode = useCallback(
    async (
      sessionId: string,
      userId: string,
      event: TIME_CODE,
      take: number
    ) => {
      try {
        await addDoc(collection(firestore, `uploads/${sessionId}/timeCodes`), {
          user: userId,
          event,
          timestamp: serverTimestamp(),
          ...(typeof take === 'number' ? { take } : {})
        });
      } catch (error) {
        logger.error('Failed to add time code:', error);
      }
    },
    [firestore]
  );

  return { addTimeCode };
}
