import { ChangeEvent, useRef } from 'react';

import colors from '../../../styles/colors.module.scss';
import styles from './DragDrop.module.scss';

import { formatBytes } from '../../../utils/format';
import { AddFolder } from '../../Icons/AddFolder/AddFolder';
import { Bin } from '../../Icons/Bin/Bin';

export interface DragDropProps {
  files: File[];
  label: string;
  onChange: (files: File[]) => void;
}

// TODO: Add max size check

export const DragDrop = ({ label, files, onChange }: DragDropProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileSelected = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      onChange([...files, ...event.target.files]);

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const onDeleteClicked = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);

    onChange(newFiles);
  };

  return (
    <div className={styles.container}>
      <div className={styles.uploadArea}>
        <input
          ref={fileInputRef}
          type="file"
          id="fileInput"
          accept="*"
          className={styles.fileInput}
          onChange={onFileSelected}
        />
        <label htmlFor="fileInput" className={styles.dragLabel}>
          <AddFolder color={colors.placeholderColor} scale={1} />
          {label}
        </label>
      </div>

      {files.length > 0 && (
        <ul id={styles.fileList} className={styles.fileList}>
          {files.map((file, idx) => (
            <li key={`${file.name}-${idx}`}>
              <span>
                {file.name} ({formatBytes(file.size)})
              </span>
              <span
                className={styles.delete}
                onClick={() => onDeleteClicked(idx)}
              >
                <Bin color={colors.lightGrey} />
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
