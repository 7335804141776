import { useEffect, useState } from 'react';

const useTimer = () => {
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  useEffect(() => {
    if (!isActive) return;

    const interval = setInterval(() => {
      setTotalSeconds((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [isActive]);

  const startTimer = () => {
    setTotalSeconds(0);
    setIsActive(true);
  };

  const resetTimer = () => {
    setIsActive(false);
  };

  return {
    minutes,
    seconds,
    totalSeconds,
    isActive,
    startTimer,
    resetTimer
  };
};

export default useTimer;
