import { useCallback, useMemo } from 'react';

import {
  addDoc,
  collection,
  getFirestore,
  serverTimestamp
} from 'firebase/firestore';

import { logger } from '../utils/logger';

export default function useSendMessageToFirestore() {
  const db = useMemo(() => getFirestore(), []);

  const sendMessageToFirestore = useCallback(
    async (sessionId: string, userId: string, message: string) => {
      if (!sessionId || !userId || !message.trim()) {
        logger.warn('Invalid message parameters:', {
          sessionId,
          userId,
          message
        });
        return;
      }

      try {
        await addDoc(collection(db, `uploads/${sessionId}/chat`), {
          userId,
          message,
          timestamp: serverTimestamp()
        });
      } catch (error) {
        logger.error('Failed to send message:', error);
        throw error; // Optionally re-throw if the caller needs to handle it
      }
    },
    [db]
  );

  return { sendMessageToFirestore };
}
