import { useCallback, useMemo } from 'react';

import { getDatabase, ref, set } from 'firebase/database';

import { UploadState } from '../types/participant.type';
import { logger } from '../utils/logger';

export default function useUploadProgress() {
  const db = useMemo(() => getDatabase(), []); // ✅ Memoized Firestore instance

  const getProgressRef = useCallback(
    (sessionId: string, participantId: string) => {
      return ref(
        db,
        `sessions/${sessionId}/participants/${participantId}/uploading`
      );
    },
    [db]
  );

  const initUploadProgress = useCallback(
    async (sessionId: string, participantId: string) => {
      try {
        await set(getProgressRef(sessionId, participantId), {
          state: UploadState.NOT_STARTED
        });
      } catch (error) {
        logger.error('Failed to initialize upload progress:', error);
      }
    },
    [getProgressRef]
  );

  const updateUploadProgress = useCallback(
    async (sessionId: string, participantId: string, progress: string) => {
      try {
        await set(getProgressRef(sessionId, participantId), {
          state: UploadState.UPLOADING,
          progress
        });
      } catch (error) {
        logger.error('Failed to update upload progress:', error);
      }
    },
    [getProgressRef]
  );

  const completeUploadProgress = useCallback(
    async (sessionId: string, participantId: string) => {
      try {
        await set(getProgressRef(sessionId, participantId), {
          state: UploadState.DONE
        });
      } catch (error) {
        logger.error('Failed to complete upload progress:', error);
      }
    },
    [getProgressRef]
  );

  return { initUploadProgress, updateUploadProgress, completeUploadProgress };
}
