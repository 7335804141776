import { useEffect } from 'react';

import {
  HMSPeer,
  selectConnectionQualityByPeerID,
  selectDominantSpeaker,
  selectIsLocalAudioEnabled,
  selectIsLocalVideoEnabled,
  selectPeerAudioByID,
  useDevices,
  useHMSStore
} from '@100mslive/react-sdk';

import { Roles } from '../../constants/roles';
import { useAppState } from '../../providers/AppState';
import { useRecordingContext } from '../../providers/RecordingProvider';
import { LocalParticipantDisplay } from '../../stories/LocalParticipantDisplay/LocalParticipantDisplay';

export interface LocalParticipantProps {
  peer: HMSPeer;
}

export default function LocalParticipant({ peer }: LocalParticipantProps) {
  const { isGroup } = useAppState();
  const { isRecording, addVideoEnabled, removeVideoEnabled } =
    useRecordingContext();
  const audioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const videoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const peerAudioLevel = useHMSStore(selectPeerAudioByID(peer.id));
  const downlinkQuality = useHMSStore(
    selectConnectionQualityByPeerID(peer?.id)
  )?.downlinkQuality;
  const isSpeaking = useHMSStore(selectDominantSpeaker)?.id === peer.id;
  const { selectedDeviceIDs } = useDevices();

  useEffect(() => {
    if (videoEnabled) addVideoEnabled(peer.id);
    else removeVideoEnabled(peer.id);
  }, [videoEnabled]);

  return (
    <LocalParticipantDisplay
      participantName={peer?.name}
      audioOn={audioEnabled}
      videoOn={videoEnabled}
      audioLevel={peerAudioLevel}
      networkQuality={downlinkQuality || 0}
      isHost={peer?.roleName === Roles.Host}
      isGuest={peer?.roleName === Roles.Guest}
      isHandRaised={peer.isHandRaised}
      isSpeaking={isSpeaking || !isGroup}
      videoTrack={peer.videoTrack}
      audioTrack={peer.audioTrack}
      isRecording={isRecording}
      isGroup={isGroup}
      selectedVideoInput={selectedDeviceIDs.videoInput}
      selectedAudioInput={selectedDeviceIDs.audioInput}
    />
  );
}
