import { Helmet } from 'react-helmet-async';

import styles from './NotFound.module.scss';

import { LottieAnimationType } from '../../constants/LottieAnimations';
import { APP_TITLE } from '../../constants/app';
import { LottieAnimation } from '../../stories/LottieAnimation/LottieAnimation';

export const NotFoundPage = () => (
  <section className={styles.container}>
    <Helmet>
      <title>{APP_TITLE} | Not Found</title>
    </Helmet>
    <div className={styles.wrapper}>
      <LottieAnimation animation={LottieAnimationType.NotFound} loop={false} />
    </div>
  </section>
);
