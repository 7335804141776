import styles from './ParticipantListItem.module.scss';

import {
  CAMERA_OFF,
  CAMERA_ON,
  DISABLE_VIDEO,
  ENABLE_VIDEO,
  MIC_OFF,
  MIC_ON,
  MUTE,
  NETWORK_QUALITY,
  RECORDING_VIDEO,
  UNMUTE
} from '../../../config/ParticipantListItem';
import { ButtonState } from '../../../constants/buttons';
import { UploadState } from '../../../types/participant.type';
import { PrimaryIconButton } from '../../IconButtons/PrimaryIconButton/PrimaryIconButton';
import { MicOff } from '../../Icons/MicOff/MicOff';
import { MicOn } from '../../Icons/MicOn/MicOn';
import { VideoOff } from '../../Icons/VideoOff/VideoOff';
import { VideoOn } from '../../Icons/VideoOn/VideoOn';
import { NetworkQuality } from '../../NetworkQuality/NetworkQuality';
import { Tooltip, TooltipPosition } from '../../Tooltip/Tooltip';

export interface ParticipantListItemProps {
  isAudioOn: boolean;
  isHandRaised: boolean;
  isHost: boolean;
  isRecording: boolean;
  isVideoOn: boolean;
  name: string;
  networkLevel: number;
  progress?: string;
  role: string;
  state?: UploadState;
  toggleAudio: () => Promise<void>;
  toggleVideo: () => Promise<void>;
}

export const ParticipantListItem = ({
  name,
  role,
  state,
  progress,
  isHandRaised,
  networkLevel,
  isAudioOn,
  isVideoOn,
  isRecording,
  isHost,
  toggleVideo,
  toggleAudio
}: ParticipantListItemProps) => {
  const determineButtonState = (isOn: boolean) =>
    !isHost || isRecording
      ? ButtonState.Disabled
      : isOn
        ? ButtonState.Enabled
        : ButtonState.Active;

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <h4>
              {name}
              {isHandRaised ? '  ✋' : ''}
            </h4>

            <h6>{role}</h6>
          </div>

          <div className={styles.controls}>
            {isHost && (
              <Tooltip
                position={TooltipPosition.WEST}
                text={NETWORK_QUALITY(networkLevel)}
                disabled={false}
              >
                <NetworkQuality quality={networkLevel} />
              </Tooltip>
            )}

            {isHost ? (
              <>
                <Tooltip
                  position={TooltipPosition.WEST}
                  text={
                    isRecording
                      ? RECORDING_VIDEO
                      : isVideoOn
                        ? DISABLE_VIDEO
                        : ENABLE_VIDEO
                  }
                  disabled={false}
                >
                  <PrimaryIconButton
                    icon={<VideoOff />}
                    state={determineButtonState(isVideoOn)}
                    onClick={toggleVideo}
                  />
                </Tooltip>
                <Tooltip
                  position={TooltipPosition.WEST}
                  text={isAudioOn ? MUTE : UNMUTE}
                  disabled={false}
                >
                  <PrimaryIconButton
                    icon={<MicOff />}
                    state={isAudioOn ? ButtonState.Enabled : ButtonState.Active}
                    onClick={toggleAudio}
                  />
                </Tooltip>
              </>
            ) : (
              <div className={styles.icons}>
                <Tooltip
                  position={TooltipPosition.WEST}
                  text={isVideoOn ? CAMERA_ON : CAMERA_OFF}
                  disabled={false}
                >
                  {isVideoOn ? <VideoOn /> : <VideoOff />}
                </Tooltip>
                <Tooltip
                  position={TooltipPosition.WEST}
                  text={isAudioOn ? MIC_ON : MIC_OFF}
                  disabled={false}
                >
                  {isAudioOn ? <MicOn /> : <MicOff />}
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        {progress && (
          <div className={styles.details}>
            {state === UploadState.NOT_STARTED && (
              <span>Waiting for upload...</span>
            )}
            {state === UploadState.UPLOADING && (
              <span>Uploading: {progress}</span>
            )}
            {state === UploadState.DONE && <span>Upload complete</span>}
          </div>
        )}
      </div>
    </section>
  );
};
