import cs from 'classnames';

import styles from './ParticipantDisplay.module.scss';

import { HAND_RAISED } from '../../config/participant';
import { LottieAnimationType } from '../../constants/LottieAnimations';
import { AudioLevel } from '../AudioLevelIndicator/AudioLevelIndicator';
import { Avatar } from '../Avatar/Avatar';
import { LottieAnimation } from '../LottieAnimation/LottieAnimation';

export interface ParticipantDisplayProps {
  audioLevel: number;
  audioOn: boolean;
  isGrid: boolean;
  isGuest: boolean;
  isHandRaised: boolean;
  isHost: boolean;
  isLocal: boolean;
  isRecording: boolean;
  isSpeaking: boolean;
  networkQuality: number;
  participantName: string;
  videoOn: boolean;
  videoRef: (instance: HTMLVideoElement | null) => void;
}

export const ParticipantDisplay = ({
  participantName,
  audioOn,
  videoOn,
  audioLevel,
  isSpeaking,
  isHandRaised,
  isLocal,
  isHost,
  isGuest,
  isRecording,
  isGrid = false,
  videoRef
}: ParticipantDisplayProps) => {
  const containerClass = cs({
    [styles.container]: true,
    [styles.grid]: isGrid
  });

  const innerContainerClass = cs({
    [styles.innerContainer]: true,
    [styles.speakingBorder]: isSpeaking
  });

  const videoClass = cs({
    [styles.video]: true,
    [styles.local]: isLocal
  });

  const role = isHost ? ' (Host)' : isGuest ? ' (Guest)' : '';
  const name = `${participantName}${role}`;

  return (
    <section className={containerClass}>
      <div className={innerContainerClass}>
        {videoOn ? (
          <video
            className={videoClass}
            ref={videoRef}
            autoPlay
            playsInline
            muted={isLocal}
          />
        ) : (
          <div className={styles.videoDisabled}>
            <Avatar name={participantName} />
          </div>
        )}
      </div>

      {!audioOn && <div className={styles.muted}>Muted</div>}

      <div className={styles.participantName} title={name}>
        {name}
      </div>

      <div className={styles.participantAudio}>
        <AudioLevel audioOn={audioOn} audioLevel={audioLevel} />
      </div>

      {isHandRaised && <div className={styles.handRaised}>{HAND_RAISED}</div>}

      {isRecording && !isGuest && (
        <div className={styles.recording}>
          <LottieAnimation
            animation={LottieAnimationType.RecordingDot}
            loop={true}
          />
        </div>
      )}
    </section>
  );
};
