import * as Sentry from '@sentry/react';

import { NODE_ENVS } from '../constants/env';

type LogLevel = 'log' | 'warn' | 'error' | 'debug';

class Logger {
  #env = process.env.NODE_ENV as NODE_ENVS;

  private formatMessage(level: LogLevel, message: string, data?: any) {
    return `[${new Date().toISOString()}] [${level.toUpperCase()}] ${message} ${
      data ? JSON.stringify(data, null, 2) : ''
    }`;
  }

  private addSentryBreadcrumb(level: LogLevel, message: string, data?: any) {
    if (typeof Sentry !== 'undefined') {
      Sentry.addBreadcrumb({
        category: 'log',
        message: this.formatMessage(level, message, data),
        level: level === 'error' ? 'error' : 'info',
        data: data || {}
      });
    }
  }

  #log(level: LogLevel, message: string, data?: any) {
    const formattedMessage = this.formatMessage(level, message, data);

    if (this.#env === NODE_ENVS.DEVELOPMENT) {
      console[level](formattedMessage);
    } else if (this.#env === NODE_ENVS.STAGING) {
      console[level](formattedMessage);
      this.addSentryBreadcrumb(level, message, data);
    } else if (this.#env === NODE_ENVS.PRODUCTION) {
      this.addSentryBreadcrumb(level, message, data);
    }
  }

  info(message: string, data?: any) {
    this.#log('log', message, data);
  }

  warn(message: string, data?: any) {
    this.#log('warn', message, data);
  }

  error(message: string, data?: any) {
    this.#log('error', message, data);
    if (typeof Sentry !== 'undefined') {
      Sentry.captureException(new Error(message));
    }
  }

  debug(message: string, data?: any) {
    this.#log('debug', message, data);
  }
}

export const logger = new Logger();
