export const LIGHT_BLUR = 0.2;
export const NORMAL_BLUR = 0.5;

export const SUPPORT_BACKGROUNDS = 'image/jpeg,image/jpg,image/png';

export const Backgrounds = {
  Image1: {
    name: 'Co-working space',
    url: `/backgrounds/background_1.jpg`,
    thumb: `/backgrounds/thumbnails/background_1.jpeg`
  },
  Image2: {
    name: 'Conference room',
    url: `/backgrounds/background_2.jpg`,
    thumb: `/backgrounds/thumbnails/background_2.jpeg`
  },
  Image3: {
    name: 'Light co-working space',
    url: `/backgrounds/background_3.jpg`,
    thumb: `/backgrounds/thumbnails/background_3.jpeg`
  },
  Image6: {
    name: 'Bright living room',
    url: `/backgrounds/background_6.jpg`,
    thumb: `/backgrounds/thumbnails/background_6.jpeg`
  },
  Image7: {
    name: 'Wood conference room',
    url: `/backgrounds/background_7.jpg`,
    thumb: `/backgrounds/thumbnails/background_7.jpeg`
  },
  Image8: {
    name: 'Bohemian living room',
    url: `/backgrounds/background_8.jpg`,
    thumb: `/backgrounds/thumbnails/background_8.jpeg`
  },
  Image10: {
    name: 'Merck',
    url: `/backgrounds/background_merck.png`,
    thumb: `/backgrounds/thumbnails/background_merck.jpeg`
  }
};

export const BACKGROUND_SIZE_ERROR = {
  title: 'Image too large',
  message:
    'The image you selected is too large. Please select a file (.jpeg,.jpg,.png or .gif) less than 5MB.'
};

export const BACKGROUND_SIZE_NOTIFICATION = {
  title: 'Background will be mirrored',
  message:
    'The background will be mirrored but it will be displayed correctly for everyone else and our editors.'
};
