import styles from './UploadProgress.module.scss';

import { LottieAnimationType } from '../../constants/LottieAnimations';
import { LottieAnimation } from '../LottieAnimation/LottieAnimation';
import { Tooltip, TooltipPosition } from '../Tooltip/Tooltip';

export interface UploadProgressProps {
  progress: number;
}

export const UploadProgress = ({ progress }: UploadProgressProps) => (
  <Tooltip
    position={TooltipPosition.SOUTH}
    text={`${progress.toFixed(0)}%`}
    disabled={false}
  >
    <div className={styles.container}>
      <div className={styles.uploading}>
        <LottieAnimation
          animation={LottieAnimationType.UploadingLight}
          loop={true}
        />
      </div>
      <span>Uploading...</span>
    </div>
  </Tooltip>
);
