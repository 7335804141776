import { useMemo, useRef } from 'react';

import {
  DeviceType,
  selectLocalPeer,
  useDevices,
  useHMSActions,
  useHMSStore
} from '@100mslive/react-sdk';

import styles from './Settings.module.scss';

import { useRecordingContext } from '../../../providers/RecordingProvider';
import { buildDeviceOptions } from '../../../utils/devices';
import { SettingsForm } from '../../Forms/SettingsForm/SettingsForm';
import { Option } from '../../Inputs/Select/SelectMenu/SelectOption/SelectOption';
import { Modal } from '../../Modal/Modal';

export interface SettingsModalProps {
  onCloseClicked: () => void;
  open: boolean;
}

export const SettingsModal = ({ open, onCloseClicked }: SettingsModalProps) => {
  const { background, setBackground } = useRecordingContext();

  const { allDevices, selectedDeviceIDs, updateDevice } = useDevices();
  const localPeer = useHMSStore(selectLocalPeer);
  const hmsActions = useHMSActions();

  const settingsTopRef = useRef<HTMLDivElement>(null);

  const onNameChanged = (name: string) => {
    void hmsActions.changeName(name);
  };

  const videoInputOptions = useMemo(
    () => buildDeviceOptions(allDevices.videoInput),
    [allDevices]
  );
  const audioInputOptions = useMemo(
    () => buildDeviceOptions(allDevices.audioInput),
    [allDevices]
  );
  const audioOutputOptions = useMemo(
    () => buildDeviceOptions(allDevices.audioOutput),
    [allDevices]
  );

  const selectedVideoInput = useMemo(
    () =>
      videoInputOptions.find((it) => it.value === selectedDeviceIDs.videoInput),
    [videoInputOptions, selectedDeviceIDs]
  );

  const selectedAudioInput = useMemo(
    () =>
      audioInputOptions.find((it) => it.value === selectedDeviceIDs.audioInput),
    [audioInputOptions, selectedDeviceIDs]
  );

  const selectedAudioOutput = useMemo(
    () =>
      audioOutputOptions.find(
        (it) => it.value === selectedDeviceIDs.audioOutput
      ),
    [audioOutputOptions, selectedDeviceIDs]
  );

  const handleDeviceChanged = (selected: Option, type: DeviceType) => {
    updateDevice({
      deviceId: selected.value,
      deviceType: type
    });
  };

  const handleClosedClicked = () => {
    if (settingsTopRef.current) {
      settingsTopRef.current.scrollIntoView({ behavior: 'instant' });
    }
    onCloseClicked();
  };

  return (
    <Modal
      open={open}
      title="Settings"
      modalId="help-modal-id"
      onCancel={handleClosedClicked}
    >
      <section className={styles.container}>
        <div ref={settingsTopRef} />
        <SettingsForm
          peer={localPeer}
          loadingPeer={!localPeer}
          onNameChanged={onNameChanged}
          background={background}
          setBackground={setBackground}
          videoInputs={videoInputOptions}
          audioInputs={audioInputOptions}
          audioOutputs={audioOutputOptions}
          selectedVideoInput={selectedVideoInput}
          selectedAudioInput={selectedAudioInput}
          selectedAudioOutput={selectedAudioOutput}
          onDeviceChanged={handleDeviceChanged}
        />
      </section>
    </Modal>
  );
};
