import { useCallback, useEffect, useMemo, useState } from 'react';

import { getDatabase, off, onValue, ref, set } from 'firebase/database';

export default function useHostRecording(
  isHost: boolean,
  eventId?: string,
  groupId?: string
) {
  const [hostRecording, setHostRecording] = useState(false);
  const db = getDatabase();

  // Memoize the Firebase reference
  const recordingGroupRef = useMemo(() => {
    return eventId && groupId
      ? ref(db, `groupRecordings/${eventId}/${groupId}/recording`)
      : null;
  }, [db, eventId, groupId]);

  useEffect(() => {
    if (!isHost && recordingGroupRef) {
      const unsubscribe = onValue(recordingGroupRef, (snapshot) => {
        setHostRecording(snapshot.val() as boolean);
      });

      return () => off(recordingGroupRef, 'value', unsubscribe);
    }
  }, [isHost, recordingGroupRef]);

  useEffect(() => {
    if (isHost && recordingGroupRef && hostRecording !== null) {
      set(recordingGroupRef, hostRecording);
    }
  }, [isHost, recordingGroupRef, hostRecording]);

  const toggleHostRecording = useCallback(() => {
    setHostRecording((prev) => {
      const newState = !prev;
      if (isHost && recordingGroupRef) {
        set(recordingGroupRef, newState);
      }
      return newState;
    });
  }, [isHost, recordingGroupRef]);

  return { hostRecording, toggleHostRecording, setHostRecording };
}
