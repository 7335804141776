import {
  ChangeEvent,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';
import ReactSlider from 'react-slider';

import cs from 'classnames';

import styles from './VideoPlayer.module.scss';

import { ButtonState } from '../../constants/buttons';
import { ScreenBlobUrl } from '../../types/screen.types';
import { formatSeconds } from '../../utils/format';
import { PrimaryIconButton } from '../IconButtons/PrimaryIconButton/PrimaryIconButton';
import { Mute } from '../Icons/Mute/Mute';
import { Pause } from '../Icons/Pause/Pause';
import { Play } from '../Icons/Play/Play';
import { Volume } from '../Icons/Volume/Volume';

const Slider = ReactSlider as any;

export interface VideoPlayerControls {
  currentTime: number;
  stopVideos: () => void;
}

export interface Annotation {
  text: string;
  timestamp: number;
}

export interface VideoPlayerProps {
  annotations: Annotation[];
  cameraUrl: string;
  screenUrls: ScreenBlobUrl[];
  total: number;
}

export const VideoPlayer = forwardRef<VideoPlayerControls, VideoPlayerProps>(
  (
    { cameraUrl, screenUrls, total, annotations }: VideoPlayerProps,
    ref: Ref<VideoPlayerControls>
  ) => {
    const cameraRef = useRef<ReactPlayer>(null);
    const screenRef = useRef<ReactPlayer>(null);

    const [hovering, setHovering] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [played, setPlayed] = useState(0);
    const [mute, setMute] = useState(false);
    const [volume, setVolume] = useState(1);

    const [screenUrl, setScreenUrl] = useState<string>();
    const [hideScreen, setHideScreen] = useState(true);

    useImperativeHandle(ref, () => ({
      currentTime: played,
      stopVideos() {
        setPlaying(false);
        cameraRef.current?.seekTo(0);
        screenRef.current?.seekTo(0);
      }
    }));

    useEffect(() => {
      const startScreenVideo = screenUrls.find((it) => it.start === 0);

      if (startScreenVideo) {
        setScreenUrl(startScreenVideo.url);
        setHideScreen(false);
      }

      return () => setPlaying(false);
    }, [cameraUrl, screenUrls]);

    const videoControls = cs({
      [styles.controls]: true,
      [styles.show]: hovering || !playing
    });

    const handleReady = () => {
      const startScreenVideo = screenUrls.find((it) => it.start === 0);

      if (startScreenVideo) {
        setScreenUrl(startScreenVideo.url);
        setHideScreen(false);
      }
    };

    const handleProgressChange = (progress: OnProgressProps) => {
      const roundedPlayed = Math.round(progress.playedSeconds);
      setPlayed(roundedPlayed);

      const startScreenVideo = screenUrls.find(
        (it) => it.start === roundedPlayed
      );
      const stopScreenVideo = screenUrls.find(
        (it) => it.stop === roundedPlayed
      );

      if (startScreenVideo) {
        setScreenUrl(startScreenVideo.url);
        setHideScreen(false);
      }
      if (stopScreenVideo) {
        setScreenUrl(stopScreenVideo.url);
        setHideScreen(true);
      }

      if (roundedPlayed === total && cameraRef.current) {
        setPlaying(false);
        setHideScreen(!screenUrl);
        setScreenUrl(undefined);

        cameraRef.current.seekTo(0);
      }

      if (roundedPlayed === 0 && cameraRef.current) {
        setHideScreen(!startScreenVideo);
      }
    };

    const handleMouseEnter = () => {
      setHovering(true);
    };

    const handleMouseLeave = () => {
      setHovering(false);
    };

    const handleVolumeChange = (v: number) => {
      setVolume(v / 100);
    };

    const handleSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (cameraRef.current) {
        cameraRef.current.seekTo(parseInt(e.target.value));
      }
    };

    const getBackgroundSize = () => ({
      background: `linear-gradient(to right, #8952FD ${(played / total) * 100}%, #252736 0%)`
    });

    const cameraVideoClass = cs({
      [styles.cameraVideo]: true,
      [styles.full]: hideScreen
    });

    const screenVideoClass = cs({
      [styles.screenVideo]: true,
      [styles.hide]: hideScreen
    });

    return (
      <section className={styles.videoContainer}>
        <div
          className={styles.videos}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={cameraVideoClass}>
            <div className={styles.video}>
              <ReactPlayer
                ref={cameraRef}
                url={cameraUrl}
                playing={playing}
                controls={false}
                muted={mute}
                volume={volume}
                onReady={handleReady}
                onProgress={handleProgressChange}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  transform: 'rotateY(180deg)'
                }}
                width="100%"
                height="100%"
              />
            </div>
          </div>

          <div className={screenVideoClass}>
            <div className={styles.video}>
              <ReactPlayer
                ref={screenRef}
                url={screenUrl}
                playing={playing}
                controls={false}
                muted={mute}
                volume={volume}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0
                }}
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
        <div
          className={videoControls}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <input
            type="range"
            min={0}
            max={total}
            step={1}
            value={played}
            onChange={handleSliderChange}
            className={styles.slider}
            style={getBackgroundSize()}
          />
          <section className={styles.annotations}>
            {annotations.map((it, idx) => (
              <span
                key={`comment-${idx}`}
                className={styles.annotation}
                style={{ left: `${(it.timestamp / total) * 100}%` }}
              ></span>
            ))}
          </section>
          <section className={styles.leftControls}>
            <PrimaryIconButton
              state={ButtonState.Enabled}
              icon={playing ? <Pause /> : <Play />}
              onClick={() => setPlaying((c) => !c)}
            />
            <PrimaryIconButton
              state={ButtonState.Enabled}
              icon={mute ? <Mute /> : <Volume />}
              onClick={() => setMute((c) => !c)}
            />
            <Slider
              min={0}
              max={100}
              defaultValue={100}
              className={styles.volumeSlider}
              thumbClassName={styles.thumbClassName}
              trackClassName={styles.trackClassName}
              onChange={handleVolumeChange}
            />
          </section>

          <section className={styles.rightControls}>
            <section className={styles.timeContainer}>
              <p>
                {formatSeconds(played)}/{formatSeconds(total)}
              </p>
            </section>
          </section>
        </div>
      </section>
    );
  }
);
