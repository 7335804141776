import { useEffect, useState } from 'react';

import styles from './CountDownTimer.module.scss';

export interface CountDownTimerProps {
  complete: () => void;
}

export const CountDownTimer = ({ complete }: CountDownTimerProps) => {
  const [countDown, setCountDown] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((c) => c - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countDown <= 0) complete();
  }, [countDown]);

  return (
    <section className={styles.wrapper}>
      <div className={styles.countDown}>
        <h4>Starting in {countDown}...</h4>
      </div>
    </section>
  );
};
