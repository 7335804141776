import cs from 'classnames';

import styles from './DangerButton.module.scss';

import { ButtonState } from '../../../constants/buttons';

export interface DangerButtonProps {
  onClick?: () => void | Promise<void>;
  state?: ButtonState;
  text: string;
}

export const DangerButton = ({
  text,
  state = ButtonState.Enabled,
  onClick
}: DangerButtonProps) => (
  <button
    className={cs({
      [styles.button]: true,
      [styles[state]]: true
    })}
    onClick={onClick}
    disabled={state === ButtonState.Loading || state === ButtonState.Disabled}
  >
    {text}
  </button>
);
