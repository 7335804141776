import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import md5 from 'md5';

import styles from './SessionEnded.module.scss';

import { APP_TITLE } from '../../constants/app';
import { submitReview } from '../../services/review';
import { Card } from '../../stories/Card/Card';
import {
  ReviewForm,
  ReviewFormData
} from '../../stories/Forms/ReviewForm/ReviewForm';
import { LogoWithText } from '../../stories/Logo/Logo';

const reviewKey = md5('review');

export const SessionEndedPage = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const params = useParams<{
    sessionId: string;
  }>();

  useEffect(() => {
    if (params.sessionId) {
      const review = window.sessionStorage.getItem(reviewKey);
      setSubmitted(review === md5(params.sessionId));
    }
  }, [params]);

  const handleReviewSubmit = async (data: ReviewFormData) => {
    if (params.sessionId) {
      // TODO: Need to make sure only one review is added maybe using a function
      setSubmitting(true);
      await submitReview(params.sessionId, data);
      window.sessionStorage.setItem(reviewKey, md5(params.sessionId));
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <section className={styles.container}>
      <Helmet>
        <title>{APP_TITLE} | Session Ended</title>
      </Helmet>
      <div className={styles.logoWrapper}>
        <LogoWithText color="white" scale={0.025} />
      </div>

      <div className={styles.wrapper}>
        <Card>
          {submitted ? (
            <div className={styles.submitted}>
              <h1>Thank you!</h1>
              <p>Your review has been submitted.</p>
            </div>
          ) : (
            <ReviewForm
              submitting={submitting}
              onSubmitClicked={handleReviewSubmit}
            />
          )}
        </Card>
      </div>
    </section>
  );
};
